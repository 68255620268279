import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(8),
    width: "300px",
    textAlign: 'left',
    flexGrow: 1
}));


const HeaderGrid = ({ image, title, content, index }) => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Box>
            {(index % 2 === 0 || !matches) && <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                useFlexGap
                sx={{ flexWrap: 'wrap' }}
            >
                <Item>
                    <StaticImage src='../../images/nesisai-security-333x500.png' alt='' />
                </Item>
                <Item>
                    <Typography variant="h4" gutterBottom><strong>{title}</strong></Typography>
                    <Typography variant="h6">
                        {content}
                    </Typography>
                </Item>
            </Stack>}
            {index % 2 !== 0 && matches && <Stack
                spacing={{ xs: 1, sm: 1 }}
                direction="row"
                useFlexGap
                sx={{ flexWrap: 'wrap' }}
            >
                <Item>
                    <Typography variant="h4" gutterBottom><strong>{title}</strong></Typography>
                    <Typography variant="h6">
                        {content}
                    </Typography>
                </Item>
                <Item>
                    <StaticImage src='../../images/nesisai-security-333x500.png' alt='' />
                </Item>
            </Stack>}
        </Box>
    );
}

const Solutions = ({ sections }) => {
    const items = (sections || []).map((section) => {
        return {
            image: '../../images/nesisai-security-333x500.png',
            title: section.Section,
            content: section.Body
        }
    })

    return (
        <>
            {items.map((item, idx) => <HeaderGrid key={idx} image={item.image} title={item.title} content={item.content} index={idx} />)}
        </>
    );
}

export default Solutions;
